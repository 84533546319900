@import '../../../variables';

::ng-deep {
    .dx-overlay-content.dx-popup-normal {
        border: none;
        border-radius: 0;

        &>.dx-toolbar {
            background-color: $clr-secondary;
            color: $clr-white;
            padding-top: 10px;
            padding-bottom: 10px;
        }

        .dx-icon-close::before {
            color: $clr-white;
        }
    }

    .dx-popup-content {
        // padding: 0;

        .modal-body {
            padding: 5px 10px;
        }
    }

    .dx-popup-wrapper .dx-toolbar.dx-popup-bottom {
        background-color: transparent;
        border-top: 1px solid #ddd;
    }
}