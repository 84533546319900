// Resets
@import "resets";

// Variables
@import "variables";

// Styling classes
@import "styling-classes";

@import "css-additionnel";

@import "../../../libraries/syslink-components/style.scss";

.drawer-toggle {
    transform: scale(1.3) !important;

    .dx-icon {
        color: $clr-white !important;
    }
}

.dx-popup-wrapper .dx-overlay-content .dx-toolbar-items-container .dx-toolbar-before .dx-toolbar-item.dx-toolbar-label {
    max-width: unset !important;
}