#drawer {
    &>.dx-drawer-wrapper>.dx-drawer-panel-content {
        background-color: $clr-secondary;

        overflow-y: auto;
        -ms-overflow-style: none; // IE 10+
        scrollbar-width: none; // Firefox

        &::-webkit-scrollbar {
            display: none; // Safari & Chrome
        }
    }

    .drawerItemIcon {
        min-width: 32px;
    }

    #drawer-tree-view {
        .drawerItemContainer {
            .drawerItemText {
                font-size: small;
                color: $clr-white
            }

            &.active {

                .drawerItemText,
                .drawerItemIcon {
                    color: $clr-primary !important;
                }
            }
        }

        li {
            &.dx-treeview-node {
                padding-left: 0;
            }

            .dx-treeview-toggle-item-visibility {
                left: unset;
                right: 5px;
                height: 48px;
                width: 30px;
                color: $clr-white !important;
                font-size: 1.7rem;
                pointer-events: none;

                &.dx-treeview-toggle-item-visibility-opened {
                    color: $clr-primary !important;
                }
            }

            &>ul {
                background-color: $clr-black;
            }
        }

        .dx-state-hover {

            .drawerItemText,
            .drawerItemIcon {
                color: $clr-primary !important;
            }

            // TODO : Not working on FireFox
            // https://caniuse.com/?search=css-has
            // https://stackoverflow.com/questions/73936048/how-do-you-enable-has-selector-on-firefox
            &+.dx-treeview-toggle-item-visibility {
                color: $clr-primary !important;
            }
        }

        // TODO : Not working on FireFox
        // https://caniuse.com/?search=css-has
        // https://stackoverflow.com/questions/73936048/how-do-you-enable-has-selector-on-firefox
        .dx-state-hover:has(> .drawerItemContainer.active) {
            &+.dx-treeview-toggle-item-visibility {
                color: $clr-primary !important;
            }
        }

        ul .dx-treeview-node-container {
            padding-left: 1rem;
        }
    }
}