::ng-deep {
    .badge {
        border-radius: 50%;

        img {
            border-radius: 50%;
        }

        &.small {
            width: 1rem;
            height: 1rem;

            span {
                font-size: .5rem;
            }
        }

        &.medium {
            width: 1.5rem;
            height: 1.5rem;

            span {
                font-size: .75rem;
            }
        }

        &.big {
            width: 2rem;
            height: 2rem;

            span {
                font-size: 1rem;
            }
        }
    }
}