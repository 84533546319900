@import "variables.scss";

$types: (
  'text': 'color',
  'bg': 'background-color',
);
$colors: (
  'accent': $clr-accent,
  'text': $clr-text,
  'bg': $clr-bg,
  'border': $clr-border,
  'success': $clr-success,
  'warning': $clr-warning,
  'danger': $clr-danger,

  'primary': $clr-primary,
  'secondary': $clr-secondary,
  'white': $clr-white,
  'black': $clr-black,
  'gray': $clr-gray,
);

@each $typeName, $typeValue in $types {
  @each $name, $color in $colors {
    .#{$typeName}-#{$name} {
      #{$typeValue}: $color !important;
    }
  }
}