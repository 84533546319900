.syslink-tabs {
    background-color: $clr-tab-bg;

    .dx-tab {
        width: max-content !important;
        padding: 0.25rem 2rem;
    }

    .dx-tabs-wrapper {
        display: block;
    }

    .dx-tab-selected, .dx-state-hover{
        background-color: $clr-gray;
        color: $clr-white;
    }
}

.syslink-tab {
    border: 1px solid $clr-border;
    border-top: none;
}